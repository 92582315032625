import { Button, Paper, Stack } from '@mui/material';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import React, { useEffect, useState } from "react";
import { db } from './index';

export const AddItemForm = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    sku: '',
    price: '',
    qty: '',
    lowStockNotification: '',
    mainPart: false,
    supplierLink: ''
  });

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        setSuccess(null);
      }, 5000);
    }
  }, [success]);

  const validateForm = () => {
    const { name, sku, price, qty, lowStockNotification, supplierLink } = formData;

    if (!name || !sku || !price || !qty || !lowStockNotification) {
      return "All fields are required.";
    }

    if (isNaN(price) || price < 0) {
      return "Price must be a non-negative number.";
    }

    if (isNaN(qty) || qty < 0 || !Number.isInteger(Number(qty))) {
      return "Quantity must be a non-negative integer.";
    }

    if (isNaN(lowStockNotification) || lowStockNotification < 0 || !Number.isInteger(Number(lowStockNotification))) {
      return "Low Stock Notification must be a non-negative integer.";
    }

    // eslint-disable-next-line
    if (supplierLink && !/^https?:\/\/[\w\-]+(\.[\w\-]+)+[/#?]?.*$/.test(supplierLink)) {
      return "Supplier Link must be a valid URL.";
    }

    return null;
  };

  const onAddItem = async () => {  
    setLoading(true);
    setError(null);
    setSuccess(null);

    const errorMessage = validateForm();
    if (errorMessage) {
      setError(errorMessage);
      setLoading(false);
      return;
    }

    try {
      await db.collection("inventory").doc(formData.sku).set(formData);
      setSuccess(true);
      setFormData({
        name: '',
        sku: '',
        price: '',
        qty: '',
        lowStockNotification: '',
        mainPart: false,
        supplierLink: ''
      });
    } catch (err) {
      setError('Error: Unable to add item. Please try again.');
    }

    setLoading(false);
  }

  const handleChange = (e) => {
    const { id, value, type, checked } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [id]: type === 'checkbox' ? checked : value
    }));
  }

  return (
    <Paper elevation={3} className='container'>
      <Box paddingX='10vw'>
        <h1>Add Inventory Item</h1>
        <Stack spacing={2}>
          <TextField
            required
            type='text'
            id="name"
            label="Product Name"
            variant="outlined"
            value={formData.name}
            onChange={handleChange}
          />
          <TextField
            required
            type='text'
            id="sku"
            label="SKU"
            variant="outlined"
            value={formData.sku}
            onChange={handleChange}
          />
          <TextField
            required
            type='number'
            id="price"
            label="Price ($)"
            variant="outlined"
            value={formData.price}
            onChange={handleChange}
          />
          <TextField
            required
            type='number'
            id="qty"
            label="Quantity"
            variant="outlined"
            value={formData.qty}
            onChange={handleChange}
          />
          <TextField
            required
            type='number'
            id="lowStockNotification"
            label="Low Stock Notification"
            variant="outlined"
            value={formData.lowStockNotification}
            onChange={handleChange}
          />
          <FormControlLabel
            control={<Checkbox id="mainPart" checked={formData.mainPart} onChange={handleChange} />}
            label="Main Part"
          />
          <TextField
            type='url'
            id="supplierLink"
            label="Supplier Link"
            variant="outlined"
            value={formData.supplierLink}
            onChange={handleChange}
          />
        </Stack>
        <Box marginY={2}>
          <Button onClick={onAddItem} variant="contained">Submit</Button>
        </Box>
        <Box>
          {loading ? <CircularProgress /> : null}
          {error ? <Alert severity='error'>{error}</Alert> : null}
          {success ? <Alert>Item has been added successfully!</Alert> : null}
        </Box>
      </Box>
    </Paper>
  );
}
