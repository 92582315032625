import { Paper } from "@mui/material";
import img from './404.jpeg';
import { Link } from "react-router-dom";

export const NotFound = () => {
  return (
    <Paper elevation={3} className="container">
      <h1>404</h1>
      <h2>You Died.</h2>
      <Link to={'/'}>Return Home</Link><br /><br />
      <img src={img} alt="404" className="img404" />
    </Paper>
  );
}