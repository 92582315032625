import {
  Button, Paper, Stack, Select, MenuItem,
  FormControl, InputLabel, List, ListItem,
  ListItemText, IconButton, Checkbox, FormControlLabel
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import TextField from '@mui/material/TextField';
import 'firebase/compat/firestore';
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import { db } from './index';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const EditItemForm = () => {
  const itemSku = useQuery().get('sku');
  const [item, setItem] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [selectedDependency, setSelectedDependency] = useState('');
  const [dependencyList, setDependencyList] = useState([]);
  const [possibleDependencies, setPossibleDependencies] = useState([]);

  useEffect(() => {
    if (success) {
      const timer = setTimeout(() => {
        setSuccess(null);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [success]);

  const validateForm = () => {
    const name = document.getElementById('name').value;
    const sku = document.getElementById('sku').value;
    const price = document.getElementById('price').value;
    const qty = document.getElementById('qty').value;
    const lowStockNotification = document.getElementById('lowStockNotification').value;
    const supplierLink = document.getElementById('supplierLink').value || null;

    if (!name || !sku || !price || (!item?.mainPart && (!qty || !lowStockNotification))) {
      return "All fields are required.";
    }

    if (isNaN(price) || price < 0) {
      return "Price must be a non-negative number.";
    }

    if (!item?.mainPart && (isNaN(qty) || qty < 0 || !Number.isInteger(Number(qty)))) {
      return "Quantity must be a non-negative integer.";
    }

    if (!item?.mainPart && (isNaN(lowStockNotification) || lowStockNotification < 0 || !Number.isInteger(Number(lowStockNotification)))) {
      return "Low Stock Notification must be a non-negative integer.";
    }
    
    // eslint-disable-next-line
    if (supplierLink && !/^https?:\/\/[\w\-]+(\.[\w\-]+)+[/#?]?.*$/.test(supplierLink)) {
      return "Supplier Link must be a valid URL.";
    }

    return null;
  };

  const onEditItem = async () => {
    setLoading(true);
    setError(null);
    setSuccess(null);

    const name = document.getElementById('name').value;
    const sku = document.getElementById('sku').value;
    const price = document.getElementById('price').value;
    const qty = document.getElementById('qty')?.value;
    const lowStockNotification = document.getElementById('lowStockNotification')?.value;
    const mainPart = document.getElementById('mainPart').checked;
    const supplierLink = document.getElementById('supplierLink').value || null;

    const errorMessage = validateForm();
    if (errorMessage) {
      setError(errorMessage);
      setLoading(false);
      return;
    }

    try {
      const itemRef = db.collection("inventory").doc(sku);
      await itemRef.set({
        name,
        price,
        sku,
        qty: mainPart ? undefined : qty,
        lowStockNotification: mainPart ? undefined : lowStockNotification,
        mainPart,
        supplierLink,
        dependencies: dependencyList
      }, { merge: true });
      setSuccess("Update complete!");
    } catch (err) {
      setError('Error updating item.');
    } finally {
      setLoading(false);
    }
  };

  const onDeleteItem = async (sku) => {
    setLoading(true);
    setError(null);
    setSuccess(null);
    try {
      const itemRef = db.collection("inventory").doc(sku);
      await itemRef.delete();
      setSuccess("Item Deleted! Redirecting to home..");
      setTimeout(() => {
        window.location.href = '/';
      }, 3000);
    } catch (err) {
      setError('Error deleting item.');
    } finally {
      setLoading(false);
    }
  };

  const getItem = async (sku) => {
    try {
      const itemRef = db.collection("inventory").doc(sku);
      const doc = await itemRef.get();
      if (doc.exists) {
        setItem(doc.data());
      } else {
        setError("Item not found.");
      }
    } catch (err) {
      setError('Error fetching item.');
    }
  };

  const handleAddDependency = () => {
    const dependency = possibleDependencies?.find(x => x.sku === selectedDependency);
    if (dependency && !dependencyList?.some(dep => dep.sku === dependency.sku)) {
      setDependencyList([...dependencyList, { ...dependency, qty: 1 }]);
    }
  };

  const handleDeleteDependency = (index) => {
    const newDependencyList = [...dependencyList];
    newDependencyList.splice(index, 1);
    setDependencyList(newDependencyList);
  };

  const getPossibleDependencies = async (itemSku) => {
    try {
      const inventoryRef = db.collection('inventory');
      const results = await inventoryRef.get();
      let inventoryList = [];
      if (!results.empty) {
        results.forEach(element => {
          const data = element.data();
          if (data.sku !== itemSku) {
            inventoryList.push(data);
          }
        });
      }
      setPossibleDependencies(inventoryList);
    } catch (err) {
      setError('Error fetching possible dependencies.');
    }
  };

  useLayoutEffect(() => {
    if (item === null) {
      getItem(itemSku);
      getPossibleDependencies(itemSku);
    }
  }, [itemSku, item]);

  useEffect(() => {
    if (item?.dependencies) {
      setDependencyList(item.dependencies);
    }
  }, [item]);

  const incrementQty = (index) => {
    setDependencyList(prevList => {
      const newList = [...prevList];
      newList[index] = {
        ...newList[index],
        qty: newList[index].qty + 1
      };
      return newList;
    });
  };

  const decrementQty = (index) => {
    setDependencyList(prevList => {
      const newList = [...prevList];
      newList[index] = {
        ...newList[index],
        qty: Math.max(newList[index].qty - 1, 1)
      };
      return newList;
    });
  };

  return (
    <Paper elevation={3} className='container'>
      <Box paddingX='10vw'>
        <h1>Edit Inventory Item</h1>
        {item ?
          <>
            <Stack spacing={2}>
              <TextField required type='text' id="name" label="Product Name" variant="outlined" defaultValue={item?.name} />
              <TextField required type='text' id="sku" label="SKU" variant="outlined" defaultValue={item?.sku} />
              <TextField required type='number' id="price" label="Price ($)" variant="outlined" defaultValue={item?.price} />
              {!item.mainPart && (
                <>
                  <TextField required type='number' id="qty" label="Quantity" variant="outlined" defaultValue={item?.qty} />
                  <TextField required type='number' id="lowStockNotification" label="Low Stock Notification" variant="outlined" defaultValue={item?.lowStockNotification} />
                </>
              )}
              <FormControlLabel
                control={<Checkbox id="mainPart" defaultChecked={item?.mainPart} />}
                label="Main Part"
              />
              <TextField type='url' id="supplierLink" label="Supplier Link" variant="outlined" defaultValue={item?.supplierLink} />
            </Stack>
            <Box sx={{ justifyContent: "space-between", display: "flex" }} marginY={2}>
              <Button onClick={onEditItem} variant="contained">Save Changes</Button>
              <Button onClick={() => onDeleteItem(item?.sku)} color={"error"} variant='outlined'>Delete</Button>
            </Box>
            <Box>
              <h2>Dependencies</h2>
            </Box>
            <Box marginY={2}>
              <FormControl fullWidth>
                <InputLabel id="dependency-select-label">Select a part</InputLabel>
                <Select
                  labelId="dependency-select-label"
                  value={selectedDependency}
                  onChange={(event) => setSelectedDependency(event.target.value)}
                >
                  {possibleDependencies.map((dependency, index) => (
                    <MenuItem key={index} value={dependency.sku}>{dependency.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Stack>
              <Button variant="contained" color="primary" onClick={handleAddDependency}>
                Add Dependency
              </Button>
            </Stack>
            <Box>
              <h3>Dependencies for this item:</h3>
              {dependencyList.length === 0 ?
                <h5>(none)</h5>
                :
                null
              }
            </Box>
            <Box sx={{ justifyContent: "space-between", display: "flex", width: "100%" }} marginY={2}>
              <List sx={{ width: "100%" }}>
                {dependencyList?.map((dependency, index) => (
                  <ListItem key={index}>
                    <ListItemText primary={dependency.name} />
                    <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
                      <span>QTY: {dependency.qty}</span>
                      <Button onClick={() => incrementQty(index)}>+</Button>
                      <Button onClick={() => decrementQty(index)}>-</Button>
                      <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteDependency(index)}>
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </ListItem>
                ))}
              </List>
            </Box>
          </>
          : null}
        <Box sx={{marginBottom: "100px"}}>
          {loading ? <CircularProgress /> : null}
          {error ? <Alert severity='error'>{error}</Alert> : null}
          {success ? <Alert>{success}</Alert> : null}
        </Box>
      </Box>
    </Paper>
  );
};
