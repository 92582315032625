import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import small from './small.png'
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import React from "react";

export const NavBar = () => {
  const [loggedIn, setLoggedIn] = React.useState('false');

  const signOut = async () => {
    console.info("Attempting to sign out..");
    await firebase.auth().signOut();
  }

  firebase.auth().onAuthStateChanged((user) => {
    if (user) {
      // logged in
      setLoggedIn(true);
    } else {
      // logged out
      setLoggedIn(false);
    }
  });

  return (
    <Box sx={{ flexGrow: 1 }}>
        <AppBar className='gray' position="static">
          <Toolbar>
            <img className='myLogo' src={small} alt='logo' />
          <Box flexGrow={1}></Box>
          {loggedIn ?
            <Button onClick={signOut} color="secondary">Sign Out</Button>
          :
            <Box>&nbsp;</Box>
          }
          </Toolbar>
        </AppBar>
      </Box>
  );
}