// SettingsPage.jsx
import React, { useState, useEffect } from 'react';
import { Paper, TextField, Button, Snackbar, Alert } from '@mui/material';
import { db } from './index'; // Adjust the path if needed

export const SettingsPage = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(true);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  useEffect(() => {
    const fetchSettings = async () => {
      const settingsDoc = await db.collection('settings').doc('emailNotification').get();
      if (settingsDoc.exists) {
        setEmail(settingsDoc.data().emailNotificationAddress);
      }
      setLoading(false);
    };

    fetchSettings();
  }, []);

  const handleSave = async () => {
    await db.collection('settings').doc('emailNotification').set({
      emailNotificationAddress: email
    });
    setOpenSnackbar(true);
  };

  const handleChange = (event) => {
    setEmail(event.target.value);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <Paper elevation={3} style={{ padding: '20px', maxWidth: '500px', margin: '20px auto' }}>
      <h2>Settings</h2>
      <form noValidate autoComplete="off">
        <TextField
          label="Email Notification Address"
          variant="outlined"
          fullWidth
          value={email}
          onChange={handleChange}
          style={{ marginBottom: '20px' }}
        />
        <Button variant="contained" color="primary" onClick={handleSave}>
          Save
        </Button>
      </form>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
          Email address saved successfully!
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default SettingsPage;
