import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { Alert } from '@mui/material';

export default function SignIn() {
  const [error, setError] = React.useState(null);
  const navigate = useNavigate(); // Use navigate hook from react-router-dom

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    firebase.auth().signInWithEmailAndPassword(data.get('email'), data.get('password'))
      .then((userCredential) => {
        // Signed in
        // var user = userCredential.user;
        console.log("authenticated successfully");
        navigate('/'); // Redirect to home page
      })
      .catch((error) => {
        // console.error(error);
        setError("Email Address or Password is incorrect.");
      });
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign In
          </Button>
          {error ? <Alert severity="error">{error}</Alert> : null}
        </Box>
      </Box>
    </Container>
  );
}
