import * as React from 'react';
import { AddItemForm } from './addItem';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { NavBar } from './navbar';
import { TableView } from './tableView';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { EditItemForm } from './editItem';
import { SettingsPage } from './settingsPage';
import { NotFound } from './notFound';
import SignIn from './login';
import { useAuth } from './auth';
import RequireAuth from './requireAuth';
import { BottomNav } from './bottomNav';

const theme = createTheme({
  palette: {
    primary: {
      main: "#0F75BC",
    },
    secondary: {
      main: "#44BAE9",
    }
  },
});

export default function App() {
  const { currentUser, loading } = useAuth();

  return (
    <ThemeProvider theme={theme}>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <>
          <NavBar loggedIn={currentUser} />
          <Router>
            <Routes>
              <Route path="/login" element={<SignIn />} />
              <Route element={<RequireAuth />}>
                <Route path="/" element={<TableView />} />
                <Route path="/add" element={<AddItemForm />} />
                <Route path="/edit" element={<EditItemForm />} />
                <Route path="/settings" element={<SettingsPage />} />
                  <Route path="*" element={<NotFound />} />
              </Route>
              </Routes>
              {currentUser ?
                <BottomNav />
              : null
              }
          </Router>
        </>
      )}
    </ThemeProvider>
  );
}