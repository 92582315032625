import * as React from 'react';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import BuildIcon from '@mui/icons-material/Build';
import AddIcon from '@mui/icons-material/Add';
import HomeIcon from '@mui/icons-material/Home';

export const BottomNav = () => {
  const [value, setValue] = React.useState(null);

  const returnHome = () => {
    window.location.href = '/';
  }

  const goToAdd = () => {
    window.location.href = '/add'
  }

  const goToSettings = () => {
    window.location.href = '/settings'
  }

  React.useLayoutEffect(() => {
    const path = window.location.pathname;
    if (path.includes('/add')) {
      setValue(2);
    } else if (path.includes("/settings")) {
      setValue(0);
    } else if (path === '/') {
      setValue(1);
    }
  }, []);

  return (
    <Box className="footer" sx={{margin: "auto"}}>
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      >
        <BottomNavigationAction onClick={goToSettings} label="Settings" icon={<BuildIcon />} />
        <BottomNavigationAction onClick={returnHome} label="Home" icon={<HomeIcon />} />
        <BottomNavigationAction onClick={goToAdd} label="Add" icon={<AddIcon />} />
      </BottomNavigation>
      <p className="watermark" sx={{textAlign: "center"}}>designed by catalina web studios</p>
    </Box>
  );
}