// v9 compat packages are API compatible with v8 code
import { Paper, Button, TableSortLabel } from '@mui/material';
import { CheckCircle } from '@mui/icons-material';
//import firebase from 'firebase/compat/app';
//import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import React, { useLayoutEffect, useState } from "react";
// import Box from '@mui/material/Box';
import { db } from './index';
// import CircularProgress from '@mui/material/CircularProgress';
// import Alert from '@mui/material/Alert';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

export const TableView = () => {
  const [items, setItems] = useState([]);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');

  const getAllItems = async () => {
    const inventoryRef = db.collection('inventory');
    const results = await inventoryRef.get();
    let inventoryList = []
    if (results.empty) {
      console.error('no items found in db');
    } else {
      results.forEach(element => {
        const data = element.data();
        inventoryList.push(data);
      });
      setItems(inventoryList);
    }
  }

  const onClickedItem = (sku) => {
    window.location.href = `/edit?sku=${sku}`
  }

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedItems = items.sort((a, b) => {
    if (orderBy === 'price' || orderBy === 'qty' || orderBy === 'lowStockNotification') {
      return order === 'asc' ? a[orderBy] - b[orderBy] : b[orderBy] - a[orderBy];
    } else {
      return order === 'asc' ? a[orderBy].localeCompare(b[orderBy]) : b[orderBy].localeCompare(a[orderBy]);
    }
  });

  useLayoutEffect(() => {
    getAllItems();
  }, []);

  return (
    <Paper elevation={3} className="container">
      <h1>Inventory</h1>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'name'}
                  direction={orderBy === 'name' ? order : 'asc'}
                  onClick={() => handleRequestSort('name')}
                >
                  Name
                </TableSortLabel>
              </TableCell>
              <TableCell align="right">
                Main Part
              </TableCell>
              <TableCell align="right">
                <TableSortLabel
                  active={orderBy === 'sku'}
                  direction={orderBy === 'sku' ? order : 'asc'}
                  onClick={() => handleRequestSort('sku')}
                >
                  SKU
                </TableSortLabel>
              </TableCell>
              <TableCell align="right">
                <TableSortLabel
                  active={orderBy === 'price'}
                  direction={orderBy === 'price' ? order : 'asc'}
                  onClick={() => handleRequestSort('price')}
                >
                  Price
                </TableSortLabel>
              </TableCell>
              <TableCell align="right">
                <TableSortLabel
                  active={orderBy === 'qty'}
                  direction={orderBy === 'qty' ? order : 'asc'}
                  onClick={() => handleRequestSort('qty')}
                >
                  Quantity
                </TableSortLabel>
              </TableCell>
              <TableCell align="right">
                <TableSortLabel
                  active={orderBy === 'lowStockNotification'}
                  direction={orderBy === 'lowStockNotification' ? order : 'asc'}
                  onClick={() => handleRequestSort('lowStockNotification')}
                >
                  Low Stock Notification
                </TableSortLabel>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedItems.map((row) => (
              <TableRow
                key={row.sku}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                className='hover'
              >
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell align="right">
                  {row.mainPart ? <CheckCircle style={{ color: 'green' }} /> : null}
                </TableCell>
                <TableCell align="right">{row.sku}</TableCell>
                <TableCell align="right">
                  {parseFloat(row.price).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                </TableCell>
                <TableCell align="right">{row.qty}</TableCell>
                <TableCell align="right">{row.lowStockNotification}</TableCell>
                <TableCell align="right"><Button onClick={() => { onClickedItem(row.sku) }}>Edit</Button></TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
